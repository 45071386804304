// Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../css/style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './EmailJS.js';

window.onload = function() {
	var navbarHeight = document.querySelector('.navbar').offsetHeight;
	var dropdownHeight = document.querySelector('.navbar-collapse.show') ? document.querySelector('.navbar-collapse.show').offsetHeight : 0;
	var offset = navbarHeight - dropdownHeight;

	// SmoothScroll
	SmoothScroll('a[href*="#"]', {
		offset: offset,
		speed: 1500,
		speedAsDuration: true,
	});

	// AOS
	AOS.init({
		duration: 1000,
		disable: 'mobile',
		easing: 'ease-out',
	});
};

// Variables
const navbarLinks = document.querySelectorAll('.navbar-nav .nav-link:not(.dropdown-toggle), #NavTrigger, .link');
const navbarToggler = document.querySelector('.navbar-toggler');
const navbarBrand = document.querySelector('.navbar-brand');
const navbar = document.querySelector('.navbar');
const missionSection = document.getElementById('NavTrigger');
let isNavbarVisible = true;
const footerHeight = document.querySelector('footer').offsetHeight;

// Footer padding
function setFooterPaddingBottom() {
	document.body.style.paddingBottom = footerHeight + 'px';
}

// Navbar Animation
function toggleNavbarVisibility() {
	const scrollY = window.scrollY;
	const missionOffsetTop = missionSection.offsetTop;

	if (scrollY > missionOffsetTop && isNavbarVisible) {
		navbar.classList.add('visible');
		isNavbarVisible = false;
	} else if (scrollY <= missionOffsetTop && !isNavbarVisible) {
		navbar.classList.remove('visible');
		isNavbarVisible = true;
	}
}

// Collapse Navbar on Click and Remove URL Fragment
navbarLinks.forEach(link => {
	link.addEventListener('click', () => {
		if (navbarToggler.getAttribute('aria-expanded') === 'true') {
			navbarToggler.click();
		}
		
		setTimeout(() => {
			history.replaceState(null, null, window.location.pathname + window.location.search);
		}, 0);
	});
});

navbarBrand.addEventListener('click', () => {
	if (navbarToggler.getAttribute('aria-expanded') === 'true') {
		navbarToggler.click();
	}
});

toggleNavbarVisibility();
setFooterPaddingBottom();

window.addEventListener('scroll', toggleNavbarVisibility);
window.addEventListener('resize', setFooterPaddingBottom);
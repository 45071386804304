// Imports
import emailjs from '@emailjs/browser';

// Keys
const publicKey = process.env.PUBLIC_KEY;
const serviceID = process.env.SERVICE_ID;
const templateID = process.env.TEMPLATE_ID;
const to_email = process.env.TO_EMAIL;
const submitButton = document.getElementById('EmailButton')

// Email Parser
emailjs.init(publicKey);

document.getElementById('emailForm').addEventListener('submit', function(event) {
	event.preventDefault();
	submitButton.innerText = "Sending...";
	submitButton.style.backgroundColor = "#7975D5";

	emailjs.send(serviceID, templateID, {
			subject: document.getElementById('subject').value,
			from_name: document.getElementById('from_name').value,
			from_email: document.getElementById('from_email').value,
			reply_to: document.getElementById('from_email').value,
			message: document.getElementById('message').value,
			to_email: to_email,
		})
		.then(function(response) {
			console.log('SUCCESS!', response.status, response.text);
			submitButton.innerText = "Sent!";
			submitButton.style.backgroundColor = "#198754";
		}, function(error) {
			console.log('FAILED...', error);
			submitButton.innerText = "Failed!";
			submitButton.style.backgroundColor = "#DC3545";
		})
		.finally(function() {
			document.getElementById('emailForm').reset();

			setTimeout(() => {
				submitButton.innerText = "Send Message!";
				submitButton.style.backgroundColor = "#63D3BB";
			}, 5000);
		});
});